// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-i-18-n-redirect-js": () => import("./../../../src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("./../../../src/pages/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-data-management-js": () => import("./../../../src/pages/data-management.js" /* webpackChunkName: "component---src-pages-data-management-js" */),
  "component---src-pages-data-protection-js": () => import("./../../../src/pages/data-protection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-enterprise-content-creators-js": () => import("./../../../src/pages/enterprise/content-creators.js" /* webpackChunkName: "component---src-pages-enterprise-content-creators-js" */),
  "component---src-pages-enterprise-index-js": () => import("./../../../src/pages/enterprise/index.js" /* webpackChunkName: "component---src-pages-enterprise-index-js" */),
  "component---src-pages-enterprise-knowledge-managers-js": () => import("./../../../src/pages/enterprise/knowledge-managers.js" /* webpackChunkName: "component---src-pages-enterprise-knowledge-managers-js" */),
  "component---src-pages-enterprise-search-expertise-js": () => import("./../../../src/pages/enterprise/search-expertise.js" /* webpackChunkName: "component---src-pages-enterprise-search-expertise-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

