exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location !== prevLocation) {
    if (window.ga) {
      window.ga('set', 'page', location.pathname + location.search + location.hash)
      window.ga('send', 'pageview')
    }

    if (window.fbq) {
      window.fbq('track', 'PageView')
    }
  }
}
